<template>
	<div>
		<Header ref="Header" />
		<img :src="menuPic" class="Big-title" />
		<div class="container">
			<div class="service-nav flex">
				<div v-for="(item,index) in nav">
					<div @click=" navSwitch(index)" class="jump" :class="navIndex==index?'service-nav-item1':'service-nav-item'">
						{{item.name}}
					</div>
				</div>
			</div>
			<div class="topic-list">
				<div v-for="(item,index) in list" :key="index">
					<div class="topic-list-item row">
						<img @click="articleJump(item)" class="topic-list-pic jump"
							:src="item.picture" />
						<div class="topic-list-right">
							<div class="service-list-up">
								<div @click="articleJump(item)" class="service-list-title jump">{{item.title}}</div>
								<div class="service-list-text jump"  @click="articleJump(item)">{{item.remark}}</div>
							</div>
							<div class="topic-list-line flex">
								<div class="topic-list-time">{{item.createTime}}</div>
								<div class="topic-item-right flex">
									<div class="topic-list-view">浏览 {{item.viewCount}}</div>
									<div @click="articleJump(item)" class="topic-list-btn jump">查看详情</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="bar-box">
				<el-pagination
					class="bar-switch"
					background
					layout="prev, pager, next, jumper, total"
					:page-size="searchDate.pageSize"
					:total="Total"
					@current-change="choosePage">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import Header from '@/components/header.vue';
	export default {
		components: {
			Header
		},
		data() {
			return {
				menuPic:'',
				bar: 1,
				nav: [
					{name:'活动案例',category:1},
					{name:'行业案例',category:7},
					{name:'活动攻略',category:8},
				],
				navIndex: 0,
				Total:0,
				searchDate:{
					category:1,
					pageNum:1,
					pageSize:10,
				},
				list:[],
			}
		},
		mounted() {
			this.$refs.Header.setIndex(3);
			// 获取菜单图片
			this.$api.getMenuPic('服务案例').then(res=>{
				console.log(res,'菜单图片');
				this.menuPic=res.data[0].picture;
			})
			this.getServiceList();
		},
		methods: {
			navSwitch(index){
				this.navIndex=index;
				this.searchDate.category=this.nav[index].category
				this.getServiceList();
			},
			// 文章链接跳转
			articleJump(item){
				if(item.linkType==1){
					this.$router.push({
						path: 'passage',
						query: {
							index:3,
							id:item.id,
						},
					});
				}else if(item.linkType==2){
					window.open(item.link, '_blank');
				}else{
					return
				}
			},
			// 选择分页
			choosePage(num){
				this.searchDate.pageNum=num;
				this.getServiceList();
			},
			//获取专题推荐列表
			getServiceList(){
				this.$api.getArticleList(this.searchDate).then(res=>{
					console.log(res,'活动案例列表');
					this.list=res.rows;
					this.Total=parseInt(res.total)
					// this.pageTotal=Math.ceil(res.total / this.searchDate.pageSize);
				});
			}
		},
	};
</script>

<style scoped>
</style>
